export default {
    init() {
        const iframeDiv = document.querySelector(".form-staffcloud #ec-recruiting");
        if (!iframeDiv) return;

        // Set current URL Search Params in iframe.
        const currentUrlParams = window.location.search;
        if (!currentUrlParams) return;

        const checkForIframe = setInterval(() => {
            // Check every 100 ms if the iframe is loaded
            const iframe = iframeDiv.querySelector("iframe");

            if (iframe) {
                // Remove intervall for checking the loaded iframe
                clearInterval(checkForIframe);
                //Join the iframe src with the search params
                const iframeSrc = iframe.src;
                const paramsToAdd = currentUrlParams.replace("?", "&");
                const newIframeSrc = iframeSrc + paramsToAdd;

                // Set the new iframe src
                iframe.src = newIframeSrc;
            }
        }, 100);

        // let iframeForm;
        // if (!iframeForm) return;

        // setTimeout(()=>{
        //     // iframeForm = iframeDiv.children[0];
        //     // iframeForm.style.height = iframeForm.contentWindow.document.body.scrollHeight + "px";
        //     // console.log(iframeForm.contentWindow.document.body.scrollHeight + "px");
        // }, 1000
        // );

        // window.addEventListener("message", (message) => {
        //     console.log(message);
        //     if (message && message.data && message.data.ticketshopHeight) {
        //         // iframeTicketshop.style.height = message.data.ticketshopHeight + "px";
        //     }
        // });


        // window.addEventListener('message', (message: any) => {
        //     if (message && message.data && message.data.ticketshopHeight) {
        //         iframeTicketshop.style.height = message.data.ticketshopHeight + 'px';
        //     }
        // });

    },
};
