import KeenSlider from "keen-slider";

export default {
    init() {
        const imageSlider = document.querySelector(".image-slider-section");
        if (!imageSlider) {
            return;
        }

        const animation = { duration: 30000, easing: (t) => t };

        new KeenSlider(
            "#image-slider",
            {
                loop: true,
                drag: true,

                breakpoints: {
                    "(min-width: 300px)": {
                        slides: {perView: 1.8, spacing: 16 },
                    },
                    "(min-width: 640px)": {
                        slides: {perView: 2.8, spacing: 16 },
                    },
                    "(min-width: 768px)": {
                        slides: {perView: 1.9, spacing: 24 },
                    },
                    "(min-width: 1024px)": {
                        slides: {perView:2.5, spacing: 24 },
                    },
                    "(min-width: 1280px)": {
                        slides: {perView:3.1, spacing: 24 },
                    },
                    "(min-width: 1536px)": {
                        slides: {perView:3.8, spacing: 24 },
                    },
                    "(min-width: 1750px)": {
                        slides: {perView:4.3, spacing: 24 },
                    },

                    "(min-width: 2000px)": {
                        slides: {perView:5, spacing: 24 },
                    },
                },
                created(s) {
                    s.moveToIdx(5, true, animation);
                },
                updated(s) {
                    s.moveToIdx(s.track.details.abs + 5, true, animation);
                },
                animationEnded(s) {
                    s.moveToIdx(s.track.details.abs + 5, true, animation);
                },
            },
            [
                (slider) => {
                    slider.on("created", () => {
                        slider.container.addEventListener("mouseover", () => {
                            slider.animator.stop();
                        });

                        slider.container.addEventListener("mouseout", () => {
                            slider.moveToIdx(
                                slider.track.details.abs + 5,
                                true,
                                animation
                            );
                        });
                    });
                },
            ]
        );
    },
};
