import KeenSlider from "keen-slider";
import windowCalculator from "./windowCalculator";

export default {
    init() {

        const heroSubpageSection = document.querySelector(".hero-sub-page");
        const heroHomeSection = document.querySelector(".hero-home");
        
        if(!heroSubpageSection && !heroHomeSection) {
            return;
        }

        let slider;

        if (windowCalculator._getWindowWidth() < 768 && heroHomeSection){
            //only the hero home page has different image sizes 
            slider = document.querySelector(".hero-auto-switch-slider-mobile");
        }else {
            slider = document.querySelector(".hero-auto-switch-slider");
        }

        if (!slider) {
            return;
        }

        const imageElements = slider.querySelectorAll(".image");

        if (!imageElements) {
            return;
        }

        const loop = imageElements.length == 1 ? false : true;

        new KeenSlider(
            "#" + slider.id,
            {
                loop: loop,
                drag: false,
                slides: { perView: 1, spacing: 0 },
            },
            [
                (slider) => {

                    function nextTimeout() {
                        setTimeout(() => {
                            slider.next();
                        }, 2000);
                    }

                    slider.on("created",nextTimeout);
                    slider.on("animationEnded", nextTimeout);
                },
            ]
        )
    },
};