import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";

export default {
    init() {
		const overlay = document.getElementById("video-overlay");
		const overlayButton = document.getElementById("overlay-button");
		const content = document.querySelector(".content");

		if (overlay && overlayButton) {
			const iframe = overlay.querySelector(".iframe");

			overlayButton.addEventListener("click", function(){
				disableBodyScroll(content);
				overlay.classList.add("show-overlay");
			})

			overlay.addEventListener("click", function(){
				enableBodyScroll(content);
				overlay.classList.remove("show-overlay");

				if (iframe) {
					let iframeSrc = iframe.src;
					iframe.src = iframeSrc;
				}
			})
		}
	},
}