export default {
    init() {
        const accordionSections = document.querySelectorAll(".accordion-section");
        
        if (!accordionSections) return;

        accordionSections.forEach((accordionSection) => {
            const contentDivs = accordionSection.querySelectorAll(".content-div");
    
            if (!contentDivs) return;
    
            if (contentDivs.length) {
                contentDivs.forEach(contentDiv => {
                    contentDiv.addEventListener("click", function (event) {
                        const openIcon = contentDiv.querySelector(".open-icon");
                        const closeIcon = contentDiv.querySelector(".close-icon");
                        
                        event.stopPropagation();
                        const textDiv = contentDiv.querySelector(".content-text-section");
    
                        if (contentDiv.classList.contains("active")) {
                            contentDiv.classList.remove("active");
                            openIcon.classList.remove("hidden");
                            closeIcon.classList.add("hidden");
                            textDiv.style.maxHeight = null;
                        } else {
                            contentDiv.classList.add("active");
                            textDiv.style.maxHeight = textDiv.scrollHeight + "px";
                            closeIcon.classList.remove("hidden");
                            openIcon.classList.add("hidden");
                        }
                    })
                });
            }
        })
    },
}
