export default {
    init() {
        const formContainers = document.querySelectorAll(".form-section");

        if (!formContainers) {
            return;
        }

        formContainers.forEach(function (formContainer) {
            const forms = formContainer.querySelectorAll("#form");

            if (!forms.length) {
                console.error("Form not found");
                return;
            }

            forms.forEach(function (form) {
                const currentForm = form.querySelector("form");
                if (!currentForm) return;

                const formHandle = currentForm.querySelector("input[name=\"handle\"]");
                if (!formHandle) return;

                // Find the CSRF token hidden input, so we can replace it
                const csrfInput = currentForm.querySelector("input[name=\"CRAFT_CSRF_TOKEN\"]");

                if (!formHandle || !csrfInput) return;

                // Fetch the new token for the form and replace the CSRF input with our new one
                fetch("/actions/formie/forms/refresh-tokens?form=" + formHandle.value)
                    .then((result) => {
                        return result.json();
                    })
                    .then((result) => {
                        csrfInput.outerHTML = result.csrf.input;
                    });
            });
        });
    },
};
