export default {

    init() {

        const questionFaq = document.getElementsByClassName("question-faq");

        if (questionFaq) { 
            const questionFaqClick = (eventHappened) => {
                let targetClicked = eventHappened.currentTarget;
                // element open
                const answerBlock = targetClicked.nextElementSibling;
                // rotate Icon
                targetClicked.querySelector(".add-icon").classList.toggle("rotated");
                targetClicked.classList.add("active");
                
                if (answerBlock.style.maxHeight) {
                    answerBlock.style.maxHeight = null;
                }
                // others
                else {
                    const allAnswerBlocks = document.getElementsByClassName("answer-faq");
                    const allQuestions = document.getElementsByClassName("question-faq");
                    for (let i = 0; i < allQuestions.length; i++) {
                        allQuestions[i].querySelector(".add-icon").classList.remove("rotated");
                    }
                    for (let i = 0; i < allAnswerBlocks.length; i++) {
                        if (allAnswerBlocks[i].style.maxHeight) {
                            allAnswerBlocks[i].style.maxHeight = null;
                        }
                    }
                    //rotate Icon
                    targetClicked.querySelector(".add-icon").classList.toggle("rotated");
                    answerBlock.style.maxHeight = answerBlock.scrollHeight + "px";
                }
            }
            
            for (let i = 0; i < questionFaq.length; i++) {
                questionFaq[i].addEventListener("click", function (event) {
                    questionFaqClick(event);

                });
            }
        }

    },
};