import KeenSlider from "keen-slider";

export default {
    init() {
        
        const sliders = document.querySelectorAll(".auto-switch-slider");

        if (!sliders) {
            return;
        }

        autoSwitchSlider(sliders);

        function autoSwitchSlider(sliders) {

            sliders.forEach(slide => {
                
                const imageElements = slide.querySelectorAll(".image");
                if (!imageElements) {
                    return;
                }

                const loop = imageElements.length == 1 ? false : true;

                new KeenSlider(
                    "#" + slide.id,
                    {
                        loop: loop,
                        drag: false,
                        slides: { perView: 1, spacing: 0 },
                    },
                    [
                        (slider) => {

                            function nextTimeout() {
                                setTimeout(() => {
                                    slider.next();
                                }, 2000);
                            }
        
                            slider.on("created", nextTimeout);
                            slider.on("animationEnded", nextTimeout);
                        },
                    ]
                )
            });
        }

    },
};