export default {
    init() {
		const textTilesSections = document.querySelectorAll(".text-tiles");
		let firstTileVisible = false;
		let viewportWidth = window.innerWidth || document.documentElement.clientWidth;

		if (textTilesSections.length > 0) {
			if (viewportWidth < 1023) {
				createObserver();
			} 

			window.addEventListener("resize", function () {
				viewportWidth = window.innerWidth || document.documentElement.clientWidth;
				if (viewportWidth < 1023) {
					createObserver();
				} 
			}, false);
		}

		function createObserver() {
			const options = {
				root: null,
				rootMargin: "0px",
				threshold: buildThresholdList(),
			};
			const observer = new IntersectionObserver(handleIntersect, options);
			textTilesSections.forEach(function (section) {
				return observer.observe(section);
			});
		}

		function buildThresholdList() {
			let thresholds = [];
			let numSteps = 20;

			for (let i = 1.0; i <= numSteps; i++) {
				let ratio = i / numSteps;
				thresholds.push(ratio);
			}

			thresholds.push(0);
			return thresholds;
		}

		function handleIntersect(entries) {
			entries.forEach(function (entry) {

				if (entry.isIntersecting) {
					let firstTile = entry.target.querySelector(".tile.order-1");

					if (entry.intersectionRatio > 0.3) {
						if (firstTile) {
							showFirstTileContent(firstTile);	
						}
					}
					
				} else {
					firstTileVisible = false;
				}

			});

		}

		function showFirstTileContent(firstTile) {
			if (!firstTile.classList.contains("on-hover") && !firstTileVisible) {
				firstTile.classList.add("on-hover");
				firstTileVisible = true;
			}

			setTimeout(function() {
				if (firstTile.classList.contains("on-hover") && firstTileVisible) {
						firstTile.classList.remove("on-hover");
					}
				}, 2000);
		}
	},
}



