import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default {
    init() {
        const body = document.querySelector("body");
        const modalBtn = document.querySelector(".application-button");
        const applictionModal = document.querySelector(".appliction-modal");

        if (!modalBtn || !body || !applictionModal) {
            return;
        }

        const form = applictionModal.querySelector(".fui-form");

        if (!form) {
            return;
        }

        modalBtn.addEventListener("click", function () {
            const modal = modalBtn.getAttribute("data-modal");
            document.getElementById(modal).style.display = "block";
            body.classList.add("body-bg-dark");
            disableBodyScroll(body);
        });

        window.addEventListener("click", function (event) {
            if (event.target.className === "appliction-modal") {
                event.target.style.display = "none";
                body.classList.remove("body-bg-dark");
                enableBodyScroll(body);
            }
        });

        ////// Mail
        const mail = form.querySelectorAll("input[type='email']");

        mail.forEach((mail) => {
            const mailParent = mail.parentElement.previousSibling;

            mail.addEventListener("focus", () => {
                mailParent.classList.add("float");
                mailParent.classList.add("selected");
                mail.classList.add("selected");
            });

            mail.addEventListener("blur", () => {
                if (mail.value.length == 0) {
                    mailParent.classList.remove("float");
                }
                mailParent.classList.remove("selected");
                mail.classList.remove("selected");
            });
        })

    },
}