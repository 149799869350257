export default {
    init() {
        const newVideoElements = document.querySelectorAll(".slide-video");

        if (!newVideoElements.length) {
            return;
        }

        newVideoElements.forEach(newVideoElement => {
            const srcDesktop = newVideoElement.dataset.srcdesktop;
            const poster = newVideoElement.dataset.poster;

            if (srcDesktop) {
                newVideoElement.setAttribute("src", newVideoElement.dataset.srcdesktop);
            }

            if (poster) {
                newVideoElement.setAttribute("poster", newVideoElement.dataset.poster);
            }

            newVideoElement.setAttribute("autoplay", "autoplay");
            newVideoElement.setAttribute("loop", "loop");
            newVideoElement.setAttribute("preload", "auto");
            newVideoElement.setAttribute("muted", "muted");

            const video = document.getElementById(newVideoElement.id);
            video.play();
        });
    },
};