import { enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import anime from "animejs";

export default {
    init() {

        //////////// Navigation
        const menu = document.getElementById("menu");
        const hamburger = document.getElementById("hamburger");
        const header = document.querySelector(".header-menu");
        const body = document.querySelector("body");
        const sectionLinks = document.querySelectorAll(".section-link");

        if (!menu || !hamburger || !header || !body || !sectionLinks) {
            return;
        }

        // We listen to the resize event
        window.addEventListener("resize", () => {
            if (menu.classList.contains("open")) {
                menu.classList.remove("open");
                hamburger.classList.remove("close");
                header.classList.remove("open-menu");
                body.classList.remove("overflow-hidden");
                body.classList.remove("fixed");
            }
        });

        if (sectionLinks) {
            sectionLinks.forEach(sectionLink => {
                sectionLink.addEventListener("click", () => {
                    if (menu.classList.contains("open")) {
                        menu.classList.remove("open");
                        hamburger.classList.remove("close");
                        header.classList.remove("open-menu");
                        body.classList.remove("overflow-hidden");
                        body.classList.remove("fixed");
                    }
                });
            });
        }

        if (hamburger) {
            hamburger.addEventListener("click", function () {

                const timeline = anime.timeline({ duration: 400, easing: "easeOutQuint" });

                // close menu
                if (menu.classList.contains("open")) {
                    menu.classList.remove("open");
                    hamburger.classList.remove("close");
                    header.classList.remove("open-menu");
                    body.classList.remove("overflow-hidden");
                    body.classList.remove("fixed");

                    timeline.add({
                        targets: "#menu",
                        opacity: 0,
                        complete: () => {
                            clearAllBodyScrollLocks();
                        },
                    });

                } else {
                    // open menu
                    enableBodyScroll(header);
                    menu.classList.add("open");
                    hamburger.classList.add("close");
                    header.classList.add("open-menu");
                    body.classList.add("overflow-hidden");
                    body.classList.add("fixed");

                    timeline.add({
                        targets: "#menu",
                        opacity: 1,
                        complete: () => {
                        },
                    });
                }
            });
        }

        if (header) {
            window.addEventListener("scroll", function () {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;


                // if (scrollTop > (window.innerHeight)) {
                // 	header.classList.add("header-shadow");
                // 		header.classList.add("white-header");
                // } else {
                // 	header.classList.remove("header-shadow");
                // 	if (header.classList.contains("white-header")) {
                // 		header.classList.remove("white-header");
                // 	}
                // }

                const heroVideo = document.querySelector(".hero-video");
                const heroText = document.querySelector(".hero-text");
                const heroSubpage = document.querySelector(".hero-subpage-section");

                // Turn header white after hero section which is height 100vh
                if (heroVideo) {
                    if (scrollTop > (window.innerHeight)) {
                        header.classList.add("header-shadow");
                        if (header.classList.contains("transparent-header")) {
                            header.classList.add("white");
                        }
                    } else {
                        removeHeaderScrollStyle();
                    }
                    // Turn header white after hero text which is height 400px
                } else if (heroText) {
                    if (scrollTop > 400) {
                        header.classList.add("header-shadow");
                        if (header.classList.contains("transparent-header")) {
                            header.classList.add("white");
                        }
                    } else {
                        removeHeaderScrollStyle();
                    }
                    // Turn header white after 80px e.g. legal pages
                } else if (heroSubpage) {
                    if (scrollTop > heroSubpage.offsetHeight) {
                        header.classList.add("header-shadow");
                        if (header.classList.contains("transparent-header")) {
                            header.classList.add("white");
                        }
                    } else {
                        removeHeaderScrollStyle();
                    }
                    // Turn header white after 80px e.g. legal pages
                }
                else {
                    if (scrollTop > 80) {
                        header.classList.add("header-shadow");
                        if (header.classList.contains("transparent-header")) {
                            header.classList.add("white");
                        }
                    } else {
                        removeHeaderScrollStyle();
                    }
                }

                function removeHeaderScrollStyle() {
                    header.classList.remove("header-shadow");
                    if (header.classList.contains("transparent-header") && header.classList.contains("white")) {
                        header.classList.remove("white");
                    }
                }
            });
        }
    },
}