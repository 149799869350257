export default {
	init() {
		const newsletterFormContainer = document.querySelector(".newsletter-form-container");

		if(!newsletterFormContainer) {
			return;
		}

		const form = newsletterFormContainer.querySelector(".fui-form");

        if (!form) {
            return;
        }

		////// Mail
		const mail = form.querySelectorAll("input[type='email']");

		mail.forEach((mail) => {
			const mailParent = mail.parentElement.nextElementSibling;

			mail.addEventListener("focus", () => {
				mailParent.classList.add("float");
				mailParent.classList.add("selected");
				mail.classList.add("selected");
			});

			mail.addEventListener("blur", () => {
				if (mail.value.length == 0) {
					mailParent.classList.remove("float");
				}
				mailParent.classList.remove("selected");
				mail.classList.remove("selected");
			});
		})
	},
}