export default {
    init() {
        const contactSections = document.querySelectorAll(".contact");
        if (!contactSections) {
            return;
        }

        contactSections.forEach((section) => {
            const contactCards = section.querySelectorAll(".contact-card");
            const heigthPotionElements = [];
            const heigthNameElements = [];
            const heigthPhoneNumberElements = [];
            const heigthMailElements = [];
            const heigthLinkedInElements = [];

            if (!contactCards) {
                return;
            }

            contactCards.forEach((card) => {

                const contactPotion = card.querySelector(".position");
                const contactName = card.querySelector(".name");
                const contactPhoneNumber= card.querySelector(".phone-number");
                const contactMail = card.querySelector(".mail");
                const contactLinkedIn= card.querySelector(".linked-in");

                if (contactPotion) {
                    heigthPotionElements.push(contactPotion.clientHeight);
                }
                if (contactName) {
                    heigthNameElements.push(contactName.clientHeight);
                }
                if (contactPhoneNumber) {
                    heigthPhoneNumberElements.push(contactPhoneNumber.clientHeight);
                }
                if (contactMail) {
                    heigthMailElements.push(contactMail.clientHeight);
                }
                if (contactLinkedIn) {
                    heigthLinkedInElements.push(contactLinkedIn.clientHeight);
                }
            });

            contactCards.forEach((card) => {

                const contactPotion = card.querySelector(".position");
                const contactName = card.querySelector(".name");
                const contactPhoneNumber= card.querySelector(".phone-number");
                const contactMail = card.querySelector(".mail");
                const contactLinkedIn= card.querySelector(".linked-in");

                if (contactPotion) {
                    contactPotion.style.height = Math.max(...heigthPotionElements) + "px";
                }
                if (contactName) {
                    contactName.style.height = Math.max(...heigthNameElements) + "px";
                }
                if (contactPhoneNumber) {
                    contactPhoneNumber.style.height = Math.max(...heigthPhoneNumberElements) + "px";
                }
                if (contactMail) {
                    contactMail.style.height = Math.max(...heigthMailElements) + "px";
                }
                if (contactLinkedIn) {
                    contactLinkedIn.style.height = Math.max(...heigthLinkedInElements) + "px";
                }
            });
        });
    },
}