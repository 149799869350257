import windowCalculator from "./windowCalculator";

export default {
	createVideoHero() {
		const newVideoElement = document.querySelector("#hero-video");
		if (newVideoElement !== null) {

			if (windowCalculator._getWindowWidth() < 992) {
				newVideoElement.setAttribute("src", newVideoElement.dataset.srcmobile);
				newVideoElement.setAttribute("poster", newVideoElement.dataset.postermobile);
			} else {
				newVideoElement.setAttribute("src", newVideoElement.dataset.srcdesktop);
				newVideoElement.setAttribute("poster", newVideoElement.dataset.poster);
			}

			newVideoElement.setAttribute("autoplay", "autoplay");
			// newVideoElement.setAttribute("loop", "loop");
			newVideoElement.setAttribute("preload", "auto");
			newVideoElement.setAttribute("muted", "muted");

			const heroVideo = document.getElementById("hero-video");
			heroVideo.play();
		}

	},
	updateVideoHero() {
		const newVideoElement = document.querySelector("#hero-video");
		let sourceChanged = false;

		if (newVideoElement !== null) {

			if (windowCalculator._getWindowWidth() < 992) {
				if (newVideoElement.dataset.srcmobile !== newVideoElement.getAttribute("src")) {
					newVideoElement.setAttribute("src", newVideoElement.dataset.srcmobile);
					newVideoElement.setAttribute("poster", newVideoElement.dataset.postermobile);
					sourceChanged = true;
				}
			} else {
				if (newVideoElement.dataset.srcdesktop !== newVideoElement.getAttribute("src")) {
					newVideoElement.setAttribute("src", newVideoElement.dataset.srcdesktop);
					newVideoElement.setAttribute("poster", newVideoElement.dataset.poster);
					sourceChanged = true;
				}
			}

			if (sourceChanged) {
				newVideoElement.setAttribute("autoplay", "autoplay");
				// newVideoElement.setAttribute("loop", "loop");
				newVideoElement.setAttribute("preload", "auto");
				newVideoElement.setAttribute("muted", "muted");

				const heroVideo = document.getElementById("hero-video");
				heroVideo.play();
			}
		}

	},
};
